<template>
  <div class="hint-con">
    <div
      class="hint"
      :class="{
        info: type == 'info',
        warning: type == 'warning',
        success: type == 'success',
      }"
    >
      <div class="icon">
        <font-awesome-icon icon="lightbulb" />
      </div>
      <div class="details">
        <h4>{{ title }}</h4>
        <p>{{ body }}</p>
      </div>
    </div>

    <div v-if="link" class="action">
      <router-link :to="link"
        ><button class="btn btn-primary sm">
          {{ actionText || "Action" }}
        </button></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "type", "body", "link", "actionText"],
  name: "Hint",
};
</script>

<style lang="scss" scoped>
.hint-con {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  padding: 15px;
  border-radius: 15px;
  margin: 10px 10px;

  &:after {
    content: "";
    clear: both;
    display: block;
  }

  .hint {
    display: flex;
  }
  /* align-items: center; */
  width: fit-content;
  .icon {
    svg {
      color: #ccc;
      font-size: 50px;
      animation: glow 2s infinite alternate;
    }
  }
  .details {
    margin-left: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
    }
  }

  &.info {
    background: #e50913;
    color: #fff;
    color: #444;
  }

  &.success {
    background: #e9ffe9;
    color: #fff;
    color: #444;

    svg {
      color: #3aff92;
    }
  }

  .action {
    float: right;
  }

  @keyframes glow {
    100% {
      color: #ffdede;
    }
  }
}
</style>
