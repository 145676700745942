import ConversationService from "../services/conversation.service";

const initialState = {
  conversations: [],
  conversation: {
    messages: [],
    participants: [],
    vendor: null,
    _id: null,
  },
};

export const conversation = {
  namespaced: true,
  state: initialState,
  actions: {
    getConversations({ commit }) {
      return ConversationService.getConversations()
        .then((data) => {
          commit("conversationsFetched", data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getConversation({ commit }, conversationId) {
      return ConversationService.getConversation(conversationId)
        .then((data) => {
          commit("conversationFetched", data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    createConversation(_, data) {
      return ConversationService.createConversation(data)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    createMessage(_, data) {
      return ConversationService.createMessage(data)
        .then((response) => {
          // commit("messageCreated", response.data.message);
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    addMessage({ commit }, data) {
      commit("addMessage", data);
    },
  },
  mutations: {
    conversationsFetched(state, conversations) {
      state.conversations = conversations;
    },
    conversationFetched(state, conversation) {
      state.conversation = conversation;
    },
    messageCreated(state, data) {
      state.message = [...state.message, data];
    },
    addMessage(state, data) {
      state.conversation.messages.push(data);
    },
  },
  getters: {
    conversations: (state) => state.conversations,
    conversation: (state) => state.conversation,
  },
};
