<template>
  <div class="sidebar-wrapper">
    <div class="header"></div>

    <div class="body">
      <ul>
        <li>
          <router-link
            :class="{ active: $route.name == 'dashboard' }"
            :to="{ name: 'dashboard' }"
          >
            <span>Dashboard</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'store-profile' }"
            ><span>Store Management</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
        <li>
          <div @click="openDrop" class="parent">
            <span style="pointer-events: none">Products</span>
            <font-awesome-icon style="pointer-events: none" icon="angle-down" />
          </div>
          <ul>
            <router-link active-class="active" :to="{ name: 'products' }"
              ><span>Products Management</span>
              <font-awesome-icon icon="angle-right" />
            </router-link>
            <router-link
              active-class="active"
              :to="{ name: 'vendor-collections' }"
              ><span>Collection Management</span>
              <font-awesome-icon icon="angle-right" />
            </router-link>
          </ul>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'orders' }"
            ><span>Orders</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'transactions' }"
            ><span>Transactions</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'payout-requests' }"
            ><span>Payout Requests</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
        <li>
          <router-link active-class="active" :to="{ name: 'id-verification' }"
            ><span>Identity Verification</span>
            <font-awesome-icon icon="angle-right" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",

  methods: {
    openDrop(e) {
      e.target.nextSibling.classList.toggle("added");
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  width: 254px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 20px;
  padding: 12px;
  /* height: 400px; */
  box-shadow: 0px 0px 13px #efefefc9;
  position: sticky;
  top: 80px;

  ul {
    li {
      div.parent,
      a {
        color: #444;
        font-weight: 500;
        padding: 15px 10px;
        background: #e9e9e924;
        margin: 5px 0px;
        display: flex;
        border-radius: 16px;
        justify-content: space-between;
        align-items: center;
        transition: all 0.5s ease;
        font-weight: 500;

        &.active,
        &:hover {
          color: #e50913;
          box-shadow: 0px 1px 3px #ffcaca;
          transform: translateX(20px);
          background: #fff;
        }
      }

      ul {
        transform: translateX(12px);
        max-height: 0px;
        overflow-y: clip;
        transition: 0.3s ease;
        opacity: 0;

        &.added {
          opacity: 1;
          max-height: 300px;
        }
      }
      div.parent {
        cursor: pointer;
        &:hover {
          transform: translateX(0px);
        }
      }
    }
  }
}
</style>
