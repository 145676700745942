<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9998 2.66675C8.65317 2.66675 2.6665 8.65341 2.6665 16.0001C2.6665 23.3467 8.65317 29.3334 15.9998 29.3334C23.3465 29.3334 29.3332 23.3467 29.3332 16.0001C29.3332 8.65341 23.3465 2.66675 15.9998 2.66675ZM21.7998 20.7601C21.6132 21.0801 21.2798 21.2534 20.9332 21.2534C20.7598 21.2534 20.5865 21.2134 20.4265 21.1067L16.2932 18.6401C15.2665 18.0267 14.5065 16.6801 14.5065 15.4934V10.0267C14.5065 9.48008 14.9598 9.02675 15.5065 9.02675C16.0532 9.02675 16.5065 9.48008 16.5065 10.0267V15.4934C16.5065 15.9734 16.9065 16.6801 17.3198 16.9201L21.4532 19.3867C21.9332 19.6667 22.0932 20.2801 21.7998 20.7601Z"
      fill="#B70C01"
    />
  </svg>
</template>

<script>
export default {
  name: "IconsTime",
};
</script>

<style></style>
