<template>
  <div>
    <Form @submit="handleDone" :validation-schema="schema">
      <div class="contain">
        <div class="field">
          <label for="id_type">Identity Proof</label>
          <Field as="select" name="id_type" class="form-control">
            <option disabled>Select Identity Proof</option>
            <option value="Passport" selected>Passport</option>
            <option value="Drivers Licence">Drivers Licence</option>
          </Field>
          <ErrorMessage name="id_type" class="error-feedback" />
        </div>

        <div class="field">
          <label for="middle_name">Date Expired</label>
          <Datepicker
            v-model="date"
            :enableTimePicker="false"
            :minDate="new Date()"
          ></Datepicker>
        </div>

        <div class="my-4">
          <label for="id">
            <div class="upload-con">
              <!-- <img v-if="url" :src="url" alt="" /> -->
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                  />
                </svg>
                <div>
                  <h4 v-if="fileSelected">{{ fileSelected.name }}</h4>
                  <h4 v-else>Upload Id</h4>
                </div>
              </div>
            </div>
          </label>
          <input
            type="file"
            id="id"
            style="display: none"
            @change="(e) => processFormImg(e)"
          />
        </div>

        <div class="field">
          <button
            class="btn btn-primary btn-block btn-rounded"
            :disabled="loading || !url"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Submit</span>
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  porps: ["loading"],
  name: "IdentityProof",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      id_type: yup.string().required("Identity Proof is required!"),
    });

    return {
      message: "",
      schema,
      date: "",
      url: "",
      fileSelected: null,
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {},
  methods: {
    processFormImg(e) {
      const file = e.target.files[0];
      this.fileSelected = file;
      this.url = URL.createObjectURL(file);
    },
    async handleDone(user) {
      if (this.date !== "" && this.fileSelected) {
        user.expired_at = this.date;
        user.file = this.fileSelected;

        console.log(this.fileSelected);

        if (!this.checkIfFilesAreCorrectType())
          return this.toast(
            "error",
            "Invalid file type. (JPG,JPEG,PNG) is Required"
          );
        if (!this.checkIfFilesAreTooBig())
          return this.toast(
            "error",
            "File too big. File must be less than 1mb"
          );
        this.loading = true;
        this.$store.dispatch("vendor/updateForm", user);

        this.$store.dispatch("vendor/register").then(
          () => {
            this.$router.push("/vendor/payment");
            this.parseSuccess("Vendor Registration Successful");
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.parseError(error);
          }
        );

        await this.$emit("next", user);
        this.loading = false;
      } else {
        this.toast("error", "All field in this section is required");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  max-width: 450px !important;
  margin: auto;
  .seller-logo {
    justify-content: center;
  }
}

.card-container.card {
  padding: 20px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 4px;

  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.label-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.error-feedback {
  color: red;
}
.or {
  color: #727171;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 30%;
    height: 1px;
    background: #dfdfdf;
  }
  &::before {
    left: -35%;
  }
  &::after {
    right: -35%;
  }
}

.upload-con {
  height: 50px;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 0px 10px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  h4 {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    margin: 0px 8px;
  }

  div {
    display: flex;
    align-items: center;
  }

  svg {
    stroke: rgba(0, 0, 0, 0.7);
    height: 30px;
    width: 30px;
  }
}
</style>
