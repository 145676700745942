export const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "FCT", // Federal Capital Territory
];

export const cities = [
  "Abia",
  "Aba",
  "Umuahia",
  "Arochukwu",
  "Ohafia",
  "Bende",
  "Adamawa",
  "Yola",
  "Mubi",
  "Jimeta",
  "Ganye",
  "Numan",
  "Akwa Ibom",
  "Uyo",
  "Eket",
  "Ikot Abasi",
  "Oron",
  "Ikot Ekpene",
  "Anambra",
  "Awka",
  "Onitsha",
  "Nnewi",
  "Obosi",
  "Aguata",
  "Bauchi",
  "Bauchi",
  "Azare",
  "Misau",
  "Jamaare",
  "Ganjuwa",
  "Bayelsa",
  "Yenagoa",
  "Brass",
  "Ogbia",
  "Sagbama",
  "Nembe",
  "Benue",
  "Makurdi",
  "Gboko",
  "Otukpo",
  "Katsina-Ala",
  "Ado",
  "Borno",
  "Maiduguri",
  "Jere",
  "Monguno",
  "Bama",
  "Dikwa",
  "Cross River",
  "Calabar",
  "Ugep",
  "Ogoja",
  "Ikom",
  "Obudu",
  "Delta",
  "Warri",
  "Asaba",
  "Sapele",
  "Ughelli",
  "Agbor",
  "Ebonyi",
  "Abakaliki",
  "Afikpo",
  "Onueke",
  "Ivo",
  "Ishielu",
  "Edo",
  "Benin City",
  "Auchi",
  "Igarra",
  "Uromi",
  "Ekpoma",
  "Ekiti",
  "Ado Ekiti",
  "Ikere Ekiti",
  "Ode Ekiti",
  "Iloro Ekiti",
  "Emure Ekiti",
  "Enugu",
  "Enugu",
  "Nsukka",
  "Oji River",
  "Udi",
  "Gombe",
  "Gombe",
  "Billiri",
  "Kaltungo",
  "Kumo",
  "Dukku",
  "Imo",
  "Owerri",
  "Okigwe",
  "Orlu",
  "Umuahia",
  "Nsukka",
  "Jigawa",
  "Dutse",
  "Hadejia",
  "Gumel",
  "Kazaure",
  "Birnin Kudu",
  "Kaduna",
  "Kaduna",
  "Zaria",
  "Kafanchan",
  "Soba",
  "Ikara",
  "Kano",
  "Kano",
  "Gwale",
  "Dala",
  "Fagge",
  "Katsina",
  "Katsina",
  "Daura",
  "Funtua",
  "Malumfashi",
  "Kankia",
  "Kebbi",
  "Birnin Kebbi",
  "Yauri",
  "Jega",
  "Dandi",
  "Zuru",
  "Kogi",
  "Lokoja",
  "Okene",
  "Idah",
  "Kabba",
  "Ankpa",
  "Kwara",
  "Ilorin",
  "Offa",
  "Omu Aran",
  "Jebba",
  "Pategi",
  "Lagos",
  "Lagos",
  "Ikeja",
  "Surulere",
  "Epe",
  "Badagry",
  "Nasarawa",
  "Lafia",
  "Keffi",
  "Akwanga",
  "Karu",
  "Nasarawa",
  "Niger",
  "Minna",
  "Bida",
  "Suleja",
  "Kontagora",
  "Lapai",
  "Ogun",
  "Abeokuta",
  "Ijebu Ode",
  "Sagamu",
  "Ilaro",
  "Ota",
  "Ondo",
  "Akure",
  "Ondo",
  "Owo",
  "Idanre",
  "Ikare",
  "Osun",
  "Osogbo",
  "Ile Ife",
  "Iwo",
  "Ede",
  "Ila Orangun",
  "Oyo",
  "Ibadan",
  "Ogbomoso",
  "Iseyin",
  "Oyo",
  "Shaki",
  "Plateau",
  "Jos",
  "Pankshin",
  "Shendam",
  "Langtang",
  "Mangu",
  "Rivers",
  "Port Harcourt",
  "Aba",
  "Opobo",
  "Bonny",
  "Degema",
  "Sokoto",
  "Sokoto",
  "Tambuwal",
  "Wurno",
  "Gwadabawa",
  "Kware",
  "Taraba",
  "Jalingo",
  "Bali",
  "Wukari",
  "Ibi",
  "Takum",
  "Yobe",
  "Damaturu",
  "Nguru",
  "Potiskum",
  "Gashua",
  "Geidam",
  "Zamfara",
  "Gusau",
  "Tsafe",
  "Bungudu",
  "Kaura Namoda",
  "Anka",
  "FCT Abuja",
  "Abuja",
  "Gwagwalada",
  "Kuje",
  "Bwari",
  "Kwali",
];

export const shipping_status = [
  "Pending",
  "Confirmed",
  "Shipped",
  "Delivered",
  "Cancelled",
];

export const cancellation_reasons = [
  {
    reason: "Product Unavailability",
    description:
      "Sorry, but the product you ordered is currently out of stock or no longer available for purchase.",
  },
  {
    reason: "Customer Request",
    description:
      "We received a request from you to cancel this order. If you have any questions, feel free to reach out to us.",
  },
  {
    reason: "Pricing Error",
    description:
      "Apologies, there was a mistake in the pricing of the product, and we cannot fulfill the order at the stated price.",
  },
  {
    reason: "Shipping Constraints",
    description:
      "Unfortunately, we are unable to ship the product within the specified timeframe or to the requested location.",
  },
  {
    reason: "Fraudulent Activity",
    description:
      "We suspect fraudulent activity associated with this order. For security reasons, we are canceling the transaction.",
  },
  {
    reason: "Vendor Error",
    description:
      "Regrettably, we made a mistake in listing or processing your order. We apologize for the inconvenience.",
  },
  {
    reason: "Damaged Product",
    description:
      "We inspected the product and found it to be damaged or defective, making it unfit for shipment.",
  },
  {
    reason: "Unexpected Circumstances",
    description:
      "Due to unforeseen circumstances such as natural disasters or supplier issues, we're unable to fulfill your order at this time.",
  },
  {
    reason: "Regulatory Compliance",
    description:
      "Your order violates regulatory or legal requirements, prompting us to cancel the transaction.",
  },
  {
    reason: "Customer Non-Responsiveness",
    description:
      "We've been unable to reach you for confirmation or further details regarding your order. As a result, we are canceling it.",
  },
];
