<template>
  <label :for="copyImages[position] ? '' : 'display'">
    <div class="slot" :class="{ selected: type == 'selected' }">
      <div
        v-if="copyImages[position]"
        class="delete-btn"
        @click="(e) => deleteImg(e, position)"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div
        class="delete-btn"
        @click="(e) => deleteImgData(e, url)"
        v-else-if="url"
      >
        <font-awesome-icon icon="times" />
      </div>
      <img v-if="url" :src="url" alt="" />
      <img
        v-else-if="copyImages[position]"
        :src="copyImages[position].url"
        alt=""
      />
      <div v-else class="icon">
        <font-awesome-icon icon="plus" />
        <input
          type="file"
          id="display"
          style="display: none"
          accept=".png,.PNG,.jpeg,.JPEG,.jpg,.JPG"
          @change="(e) => processFormImg(e)"
        />
      </div>
    </div>
  </label>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  props: ["type", "images", "position", "editing"],

  data() {
    return {
      fileSelected: null,
      loading: false,
      url: null,
      copyImages: [],
    };
  },
  watch: {
    images(newVal) {
      this.copyImages = newVal;
    },
  },
  methods: {
    processFormImg(e) {
      const file = e.target.files[0];
      if (file.size <= 2 * 1024 * 1024) {
        this.fileSelected = file;
        this.url = URL.createObjectURL(file);
        this.$emit("imageAdded", {
          url: this.url,
          file,
        });
      } else if (!this.is_allowed_file(file.type)) {
        this.toast(
          "error",
          `Supported file type are (${this.allowedFiles.image.join(",")})
        `
        );
      } else {
        this.toast("error", "File too large, Max file size is 2MB");
      }

      // this.uploadImage();
    },
    deleteImg(e, index) {
      let image = this.images.find((img, i) => i == index);
      if (!image.filename) {
        this.deleteImgData(e, this.url);
        return;
      }

      let data = {
        image_id: image._id,
        filename: image.filename,
        product_id: this.$route.params.id,
      };
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("product/deleteFile", data)
            .then(() => {
              this.loading = false;
              this.$emit("uploaded", true);
              this.toast("success", "File Deleted");

              this.copyImages = this.images.filter(
                (img) => img.filename !== image.filename
              );
              this.$emit("imageRemoved", image.url);
            })
            .catch((err) => {
              this.serverErrors = err.response.data.msg;
              this.loading = false;
              this.toast("error", this.serverErrors);
            });
          // Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    deleteImgData(e, url) {
      e?.preventDefault();
      this.$emit("imageRemoved", url);
      this.url = "";
      return;
    },
    uploadImage() {
      this.loading = true;

      this.$store
        .dispatch("product/uploadFile", {
          file: this.fileSelected,
          product_id: this.$route.params.id,
        })
        .then(() => {
          this.loading = false;
          this.$emit("uploaded", true);
          this.toast("success", "File Uploaded");
        })
        .catch((err) => {
          this.serverErrors = err.response.data.msg;
          this.loading = false;
          this.toast("error", this.serverErrors);
        });
    },
  },
  mounted() {
    this.copyImages = this.images;
  },
  name: "UploadSlot",
};
</script>

<style lang="scss">
.slot {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 15px;
  margin: 10px 10px;
  display: flex;
  border: 2px dashed gray;
  /* align-items: center; */
  width: fit-content;
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  &.selected {
    background: #ffdede;

    .icon {
      svg {
        color: #444;
      }
    }
  }
  .icon {
    svg {
      color: #ffdede;
      font-size: 30px;
    }
  }
  .details {
    margin-left: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
    }
  }

  &.info {
    background: #e50913;
    color: #fff;
    color: #444;
  }
}

.delete-btn {
  border-radius: 50%;
  text-align: center;
  background: rgba(255, 0, 0, 0.7);
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  line-height: 30px;
  color: #fff;
  position: absolute;
  cursor: pointer;
  border: 1px solid #fff;
}
</style>
