import { axiosInstance as axios } from "../plugins/axios";

const ENDPOINT = "/user";

class UserService {
  getUser() {
    return axios
      .get(ENDPOINT + "/user")
      .then((response) => {
        const {
          data: { user },
        } = response;
        if (user) {
          localStorage.setItem("user", JSON.stringify(user));
        }

        return user;
      })
      .catch(() => {
        console.log("Error");
      });
  }
  createBankAccount(data) {
    return axios.post(ENDPOINT + "/bank-accounts", data);
  }

  updateBankAccount(data) {
    return axios.patch(ENDPOINT + "/bank-accounts/" + data.id, data);
  }

  deleteBankAccount(id) {
    return axios.delete(ENDPOINT + "/bank-accounts/" + id);
  }

  getBankAccount() {
    return axios.get(ENDPOINT + "/bank-accounts");
  }
}

export default new UserService();
