import ProductService from "../services/product.service";

const initialState = {
  products: [],
  product: null,
  categories: null,
  form: {},
};

export const product = {
  namespaced: true,
  state: initialState,
  actions: {
    get({ commit }, data) {
      return ProductService.get(data).then(
        (product) => {
          commit("setProduct", product);
          return Promise.resolve(product);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getProducts({ commit }, data) {
      return ProductService.getProducts(data)
        .then((products) => {
          commit("setProducts", products);
          return Promise.resolve(products);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getCategories({ commit }, data) {
      return ProductService.getCategories(data).then(
        (categories) => {
          commit("setCategories", categories);
          return Promise.resolve(categories);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFormData() {
      return ProductService.getFormData().then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    create(_, data) {
      let formData = new FormData();

      for (var key in data) {
        if (key == "images") {
          for (let i = 0; i < data[key].length; i++) {
            formData.append("images", data[key][i]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }

      return ProductService.create(formData).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createCollection(_, data) {
      return ProductService.createCollection(data).then(
        (response) => {
          return Promise.resolve(response.data.collection);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getCollections(_, data) {
      let { id, ...params } = data;
      return ProductService.getCollections(params, id).then(
        (collections) => {
          return Promise.resolve(collections);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getCollection(_, data) {
      return ProductService.getCollection(data).then(
        (collection) => {
          return Promise.resolve(collection);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    removeCollection(_, id) {
      return ProductService.removeCollection(id).then(
        (res) => {
          return Promise.resolve(res);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    update(_, data) {
      let formData = new FormData();

      for (var key in data) {
        if (key == "images") {
          for (let i = 0; i < data[key].length; i++) {
            formData.append("images", data[key][i]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }

      return ProductService.update(formData, data.id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateStatus(_, data) {
      return ProductService.updateStatus(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteFile(_, data) {
      return ProductService.deleteFile(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    submit(_, data) {
      return ProductService.submit(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    uploadFile(_, data) {
      return ProductService.uploadFile(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    uploadMediaFile(_, data) {
      return ProductService.uploadMediaFile(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    downloadMedia(_, data) {
      return ProductService.downloadMedia(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setProduct(state, product) {
      state.product = product;
    },
    setProducts(state, products) {
      state.products = products;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
  },
  getters: {
    product: (state) => state.product,
    products: (state) => state.products,
    categories: (state) => state.categories,
  },
};
