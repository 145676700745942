<template>
  <div class="earning" :class="{ monthly: type == 'monthly' }">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="foot">
      <h2>{{ $filters.formatMoney(value) }}</h2>
      <div class="action">
        <font-awesome-icon icon="sign-in-alt" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "type", "value"],
  name: "EarningCard",
};
</script>

<style lang="scss" scoped>
.earning {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
  border-radius: 20px;
  height: 160px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 10px;

  &.monthly {
    background: #e50913;
    color: #fff;

    .title {
      color: rgba(255, 255, 255, 0.8);
    }
    .foot {
      h2 {
        color: #fff;
      }
     .action {
      background: #fff;
      color: #e50913;
    }
    }
  }

  .title {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
  }

  .foot {
    display: flex;
    justify-content: space-between;

    h2 {
      font-weight: 500;
      color: #444;
    }

    .action {
      background: #e50913;
      border-radius: 10px;
      height: 50px;
      width: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      cursor:pointer;
    }
  }
}

@media (max-width:640px) {
.earning {
  margin:10px 0px;
}
}
</style>