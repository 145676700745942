import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  states,
  cities,
  shipping_status,
  cancellation_reasons,
} from "../lib/constants.js";

let allowedFiles = {
  image: ["jpeg", "jpg", "gif", "png", "pdf", "bmp"],
  application: ["ppt", "pptx", "doc", "docx", "csv", "pdf"],
  video: ["mp4", "mov", "avi", "webm", "html5"],
};

let banks = [
  {
    bank_name: "Access Bank",
    code: "044",
  },
  {
    bank_name: "Ecobank",
    code: "050",
  },
  {
    bank_name: "Fidelity Bank",
    code: "070",
  },
  {
    bank_name: "First Bank of Nigeria",
    code: "011",
  },
  {
    bank_name: "First City Monument Bank (FCMB)",
    code: "214",
  },
  {
    bank_name: "GTBank",
    code: "058",
  },
  {
    bank_name: "Heritage Bank ",
    code: "030",
  },
  {
    bank_name: "Keystone Bank",
    code: "082",
  },
  {
    bank_name: "Stanbic IBTC Bank",
    code: "221",
  },
  {
    bank_name: "Sterling Bank",
    code: "232",
  },
  {
    bank_name: "Union Bank",
    code: "032",
  },
  {
    bank_name: "United Bank for Africa",
    code: "033",
  },
  {
    bank_name: "Unity Bank",
    code: "215",
  },
  {
    bank_name: "VFD Microfinance Ban",
    code: "090110",
  },

  {
    bank_name: "Wema Bank",
    code: "035",
  },

  {
    bank_name: "Zenith Bank",
    code: "057",
  },
];

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const mixin = {
  data() {
    return {
      allowedFiles,
      states,
      cities,
      shipping_status,
      cancellation_reasons,
    };
  },
  methods: {
    parseError(err) {
      if (!err.response)
        return Toast.fire({
          title: err.message || "Opps, something went wrong",
          icon: "error",
        });
      if (err.response.status === 422) {
        Swal.fire({
          title: "Validation Error",
          text:
            Object.values(err.response.data.errors)[0][0].msg ||
            err.response.data.error.msg,
          confirmButtonColor: "#e50913",
          icon: "error",
        });
      } else if (err.response.status === 400) {
        Toast.fire({
          title: err.response?.data?.error?.msg || err.response.data.msg,
          icon: "error",
        });
      } else if (err.response.status === 404) {
        Toast.fire({
          text: err.response.data.error.msg,
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Oops,  something went wrong!",
          text: err.response.data.error.msg || "Internal error",
          confirmButtonColor: "#e50913",
          icon: "error",
        });
      }
    },
    parseSuccess(msg) {
      Swal.fire({
        text: msg,
        confirmButtonColor: "#e50913",
        icon: "success",
      });
    },
    parseErrorMsg(msg) {
      Swal.fire({
        title: "Oops,something went wrong",
        text: msg,
        confirmButtonColor: "#e50913",
        icon: "error",
      });
    },
    toast(type, msg) {
      Toast.fire({
        icon: type,
        text: msg,
      });
    },
    is_allowed_file(file_mime) {
      const file_type = file_mime.split("/")[0];
      const file_ext = file_mime.split("/")[1];
      let fts = ["image", "video", "application"];
      console.log(file_type, file_ext);
      if (
        fts.includes(file_type) &&
        this.allowedFiles[file_type].includes(file_ext)
      )
        return true;
      return false;
    },

    checkIfFilesAreTooBig() {
      let valid = true;
      if (this.fileSelected) {
        const size = this.fileSelected.size / 1024 / 1024;
        if (size > 1) {
          valid = false;
        }
      }
      return valid;
    },
    checkIfFilesAreCorrectType() {
      let valid = true;
      if (
        !["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(
          this.fileSelected.type
        )
      ) {
        valid = false;
      }
      return valid;
    },
    confirm(msg, actionMsg) {
      return Toast.fire({
        toast: true,
        title: "Are you sure??",
        html: msg || "You won't be able to revert this!",
        icon: "warning",
        position: "bottom-end",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: actionMsg || "Yes, delete it!",
      });
    },
    getBankName(code) {
      const bank = banks.find((bank) => bank.code == code);
      return bank ? bank.bank_name : "--";
    },
    fillObject(object, data) {
      for (let i in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(i)) {
          object[i] = data[i];
        }
      }
      return object;
    },
    updateValue(event) {
      // Remove non-numeric characters from input
      const rawValue = event.target.value.replace(/[^0-9.]/g, "");
      // Convert to number
      const value = parseFloat(rawValue);
      // Format as currency
      const formatted = value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      // Update model and formatted value
      this.amount = value;
      this.formattedAmount = formatted;
    },
  },
};
