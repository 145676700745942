import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import { handleError } from "../utils";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: {
    ...initialState,
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        ({ user, vendor }) => {
          commit("loginSuccess", user);
          commit("vendor/vendorFetched", vendor, { root: true });
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    getUser({ commit }) {
      return UserService.getUser(user).then(
        (user) => {
          commit("userSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          handleError(error);
          return Promise.reject(error);
        }
      );
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (user) => {
          commit("registerSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    verifyOtp({ commit }, otp) {
      return AuthService.verifyOtp(otp).then(
        (response) => {
          commit("logout");
          AuthService.logout();
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    resendOtp() {
      return AuthService.resendOtp().then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createBankAccount(_, data) {
      return UserService.createBankAccount(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteBankAccount(_, id) {
      return UserService.deleteBankAccount(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateBankAccount(_, data) {
      return UserService.updateBankAccount(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getBankAccount() {
      return UserService.getBankAccount().then(
        (response) => {
          return Promise.resolve(response.data.back_accounts);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changePassword(_, data) {
      return AuthService.changePassword(data).then(
        () => {
          return Promise.resolve(true);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    submitId(_, data) {
      let formData = new FormData();

      for (var key in data) {
        if (key == "files") {
          for (let i = 0; i < data[key].length; i++) {
            formData.append("files", data[key][i]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }

      return AuthService.submitId(formData).then(
        () => {
          return Promise.resolve(true);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getId() {
      return AuthService.getId().then(
        (id) => {
          return Promise.resolve(id);
        },
        (error) => {
          handleError(error);
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    userSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
      state.user = user;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
  getters: {
    currentUser: (state) => state.user,
  },
};
