<template>
  <nav class="navbar">
    <div class="container">
      <router-link
        to="/"
        class="navbar-brand seller-logo"
        style="margin-top: -12px"
        ><Logo />
      </router-link>
      <div class="navbar-nav mr-auto">
        <!-- <li v-if="showAdminBoard" class="nav-item">
        <router-link to="/admin" class="nav-link">Admin Board</router-link>
      </li>
      <li v-if="showModeratorBoard" class="nav-item">
        <router-link to="/mod" class="nav-link">Moderator Board</router-link>
      </li> -->
      </div>

      <div
        v-if="!currentUser && $route.name !== 'otp-verification'"
        class="navbar-nav ml-auto"
      >
        <li class="nav-item d-none d-sm-block">
          <router-link to="/register" class="nav-link mr-4">
            Become a Vendor
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-slot="{ href, navigate }" to="/login">
            <button
              :href="href"
              @click="navigate"
              class="btn btn-primary nav-link btn-rounded sm"
            >
              <font-awesome-icon icon="sign-in-alt" /> Login
            </button>
          </router-link>
        </li>
      </div>

      <ul v-if="currentUser" class="d-none d-sm-flex logged-in ml-auto">
        <li v-if="currentUser.role == 'Vendor'" class="nav-item">
          <router-link :to="{ name: 'conversations' }">
            <font-awesome-icon icon="inbox" />
          </router-link>
        </li>
        <li v-if="currentUser.role == 'Vendor'" class="nav-item">
          <router-link :to="{ name: 'settings' }">
            <font-awesome-icon icon="cog" />
          </router-link>
        </li>
        <li v-if="currentUser.role == 'Vendor'" class="nav-item">
          <router-link :to="{ name: 'notifications' }">
            <font-awesome-icon icon="bell" />

            <div class="count badge badge-success" v-if="notificationsCount">
              {{ notificationsCount }}
            </div>
          </router-link>
        </li>
        <li class="nav-item menu-toggle">
          <div class="avatar">
            <img :src="currentUser.profile_image" wit />
          </div>

          <div class="menu">
            <ul v-if="currentUser">
              <li v-if="currentUser.role == 'Vendor'">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li @click="logout">
                Logout <small>({{ currentUser.username }})</small>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div v-if="currentUser" class="d-block d-sm-none d-flex logged-in">
        <div class="nav-item">
          <router-link :to="{ name: 'notifications' }">
            <font-awesome-icon icon="bell" />
          </router-link>
          <div class="count badge badge-success" v-if="notificationsCount">
            {{ notificationsCount }}
          </div>
        </div>
        <div class="bar" @click="open = !open">
          <font-awesome-icon v-if="!open" icon="bars" />
          <font-awesome-icon v-else icon="times" />
        </div>
      </div>

      <div class="m-sidebar d-block d-sm-none" :class="{ added: open }">
        <ul>
          <li>
            <router-link
              :class="{ active: $route.name == 'dashboard' }"
              :to="{ name: 'dashboard' }"
            >
              <span>Dashboard</span>
              <font-awesome-icon icon="angle-right" />
            </router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name: 'store' }"
              ><span>Store </span>
              <font-awesome-icon icon="angle-right" />
            </router-link>
          </li>
          <li>
            <div @click="openDrop" class="parent">
              <span style="pointer-events: none">Products</span>
              <font-awesome-icon
                style="pointer-events: none"
                icon="angle-down"
              />
            </div>
            <ul>
              <router-link active-class="active" :to="{ name: 'products' }"
                ><span>Products Management</span>
                <font-awesome-icon icon="angle-right" />
              </router-link>
              <router-link
                active-class="active"
                :to="{ name: 'vendor-collections' }"
                ><span>Products Collection</span>
                <font-awesome-icon icon="angle-right" />
              </router-link>
            </ul>
          </li>
          <li>
            <router-link active-class="active" :to="{ name: 'orders' }"
              ><span>Orders</span>
              <font-awesome-icon icon="angle-right" />
            </router-link>
          </li>
          <li>
            <router-link active-class="active" :to="{ name: 'transactions' }"
              ><span>Transactions</span>
              <font-awesome-icon icon="angle-right" />
            </router-link>
          </li>

          <li
            v-if="currentUser"
            @click="logout"
            style="position: absolute; bottom: 100px; width: 100%"
          >
            <a>
              Logout <small>({{ currentUser.username }})</small>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "Nav",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const open = ref(false);
    const openD = ref(false);
    const fetching = ref(false);
    const notificationsCount = ref(null);

    const logout = () => {
      store.dispatch("auth/logout");

      router.push("/login");
    };

    const openDrop = (e) => {
      e.target.nextSibling.classList.toggle("added");
    };

    // eslint-disable-next-line no-unused-vars
    const fetchNotifications = async () => {
      try {
        fetching.value = true;

        let notifications = await store.dispatch("vendor/getNotifications", {
          read: true,
        });

        notificationsCount.value = notifications.length;
        fetching.value = false;
      } catch (error) {
        console.log("this is error");
        fetching.value = false;
      }
    };

    const currentUser = computed(() => store.state.auth.user);
    
    onMounted(() => {
      if (currentUser?.value?.role === "Vendor") {
        fetchNotifications();
      }
    });

    watch(route, () => {
      open.value = false;
    });

    return {
      currentUser,
      logout,
      open,
      openD,
      openDrop,
      notificationsCount,
      fetching,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  /* box-shadow: 0px 1px 3px #ffcaca; */
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 999;
  border-bottom: 1px solid #d0d5dd;
}
.navbar-nav {
  li {
    font-size: 16px !important;

    a {
      color: #444;
    }
  }
}

.bar {
  box-shadow: 0px 1px 3px #ffcaca;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 25px;
  }
}

.m-sidebar {
  position: absolute;
  top: 70px;
  left: -320px;
  width: 290px;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 1px 3px #ffcaca;
  z-index: 999;
  transition: 0.3s;

  &.added {
    left: 0;
  }

  li {
    ul {
      transform: translateX(12px);
      max-height: 0px;
      overflow-y: clip;
      transition: 0.3s ease;

      &.added {
        max-height: 300px;
      }
    }
  }

  a,
  div.parent {
    transition: transform 0.2s;
    color: #444;
    display: block;
    border-bottom: 1px solid #ffcaca;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.active,
    &:hover {
      border-radius: 16px;
      color: #e50913;
      box-shadow: 0px 1px 3px #ffcaca;
      font-weight: 700;
      transform: translateX(20px);
      background: #fff;
      border-radius: 10px;
    }
  }
  div.parent {
    &:hover {
      transform: translateX(0px);
    }
  }
}

.logged-in {
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .nav-item {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    box-shadow: 0px 1px 3px #ffcaca;
    margin: 0px 15px;
    cursor: pointer;
    position: relative;

    &.menu-toggle {
      border-radius: 50%;
    }

    a {
      color: #444;
    }

    .count {
      position: absolute;
      top: -10px;
      right: -5px;
    }

    &:hover .menu {
      display: block;
    }

    svg {
      font-size: 24px;
      margin-top: 8px;
    }
  }

  .menu {
    padding: 10px;
    background: #fff;
    top: 60px;
    position: absolute;
    min-width: 200px;
    right: 0px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #ffcaca;
    display: none;
    text-align: start;
    z-index: 999;

    ul li {
      font-weight: 500;

      &:hover {
        color: #e50913;
      }
    }
    &:after {
      content: "";
      height: 50px;
      width: 50px;
      position: absolute;
      top: -50px;
      right: 0px;
    }
  }
}
</style>
