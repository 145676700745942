<template>
  <div class="">
    <div class="d-flex">
      <div class="show-display">
        <div class="seller-logo">
          <router-link to="/">
            <Logo />
          </router-link>
          <span class="ml-2">Sellers Square</span>
        </div>
        <div class="header-1 mt-4">
          <h4>
            Make more sales <br />
            while selling on Cellepay
          </h4>
        </div>

        <img src="/images/hero3.png" width="100%" alt="" />
      </div>
      <div class="form-display">
        <div class="seller-logo-m d-sm-none d-block">
          <router-link to="/">
            <Logo />
          </router-link>
          <span class="ml-2">Sellers Square</span>
        </div>

        <strong class="lg d-sm-block d-none"
          >Already has a cellepay account?<router-link to="/">
            Login</router-link
          ></strong
        >
        <div class="contain">
          <div class="card-container">
            <div class="field">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>

            <Form @submit="handleLogin" :validation-schema="schema">
              <div class="header">
                <h2>Create Account</h2>
                <p>Create an account and start selling</p>
              </div>
              <div class="field">
                <label for="firstname">First Name</label>
                <Field
                  name="firstname"
                  id="firstname"
                  type="text"
                  class="form-control"
                />
                <ErrorMessage name="firstname" class="error-feedback" />
              </div>

              <div class="field">
                <label for="lastname">Last Name</label>
                <Field
                  name="lastname"
                  type="text"
                  id="lastname"
                  class="form-control"
                />
                <ErrorMessage name="lastname" class="error-feedback" />
              </div>

              <div class="field">
                <label for="username">Username</label>
                <Field
                  name="username"
                  id="username"
                  type="text"
                  class="form-control"
                />
                <ErrorMessage name="username" class="error-feedback" />
              </div>

              <div class="field">
                <label for="email">Email Address</label>
                <Field
                  name="email"
                  id="email"
                  type="email"
                  class="form-control"
                />
                <ErrorMessage name="email" class="error-feedback" />
              </div>

              <label for="phone">Phone Number</label>
              <div class="row">
                <div class="col-4">
                  <div class="field">
                    <Field
                      id="phone"
                      as="select"
                      name="phone_ext"
                      class="form-control"
                    >
                      <option disabled>Select Extension</option>
                      <option value="+234" selected>+234</option>
                    </Field>
                    <ErrorMessage name="phone_ext" class="error-feedback" />
                  </div>
                </div>

                <div class="col-8 field">
                  <!-- <label for="phone">phone Number</label> -->
                  <Field
                    name="phone"
                    type="number"
                    placeholder="e.g, 0906383738 "
                    class="form-control"
                  />
                  <ErrorMessage name="phone" class="error-feedback" />
                </div>
              </div>

              <div class="field">
                <label for="date">Date of Birthday</label>

                <Datepicker
                  v-model="date"
                  id="date"
                  :enableTimePicker="false"
                ></Datepicker>
              </div>

              <div class="field">
                <div class="">
                  <label for="password">Password</label>
                </div>
                <Field
                  name="password"
                  id="password"
                  type="password"
                  class="form-control"
                />
                <ErrorMessage name="password" class="error-feedback" />
              </div>

              <div class="field">
                <div class="">
                  <label for="password_confirmation">Confirm Password</label>
                </div>
                <Field
                  name="password_confirmation"
                  type="password"
                  class="form-control"
                  id="password_confirmation"
                />
                <ErrorMessage
                  name="password_confirmation"
                  class="error-feedback"
                />
              </div>

              <div class="field mt-4">
                <button
                  class="btn btn-primary btn-block btn-rounded"
                  :disabled="loading"
                >
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Register</span>
                </button>
              </div>

              <div class="mt-4 text-center">
                <span class="or">Already have an account?</span>
                <br />
                <router-link
                  to="/login"
                  class="btn btn-primary btn-outlined btn-rounded btn-block mt-4"
                  type="button"
                >
                  <span>Login</span>
                </router-link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      email: yup.string().email().required("Email must be valid!"),
      firstname: yup.string().required("First name is required!"),
      phone: yup.string().required("Phone number is required!"),
      phone_ext: yup.string().required("Phone Extension is required!"),
      lastname: yup.string().required("Last name is required!"),
      password: yup.string().required("Password is required!"),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Password Confirmation is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
      date: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/vendor");
    }
  },
  methods: {
    async handleLogin(user) {
      try {
        this.loading = true;
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha("login");

        user.token = token;

        user.birthday = this.date;

        this.$store.dispatch("auth/register", user).then(
          () => {
            this.$router.push("/otp-verification");
          },
          (error) => {
            this.loading = false;

            if (error.response.data.global) {
              let err = null || Object.values(error.response.data.errors);
              this.parseErrorMsg(err[0][0].msg);
              window.scrollTo(0, 50);
            }
          }
        );
      } catch (error) {
        console.log(error);
        this.parseErrorMsg("Opps, something went wrong");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.show-display {
  width: 40%;
  background: #ffdedf;
  color: #2b0f0f;
  padding: 20px;
  position: sticky;
  top: 0px;
  height: 100vh;

  img {
    width: 100%;
  }

  .header-1 {
    h4 {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
    }
  }
}

.header {
  margin-bottom: 50px;
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
    color: #63637e;
  }
}
.form-display {
  width: 60%;

  position: relative;

  .lg {
    position: absolute;
    top: 30px;
    right: 50px;
  }
}

.contain {
  max-width: 450px !important;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;

  .seller-logo {
    justify-content: center;
  }
}

.card-container.card {
  padding: 20px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.label-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.error-feedback {
  color: #dc3545;
  font-size: 12px;
}
.or {
  color: #727171;
  position: relative;
  font-size: 12px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 6px;
    width: 30%;
    height: 1px;
    background: #dfdfdf;
  }
  &::before {
    left: -35%;
  }
  &::after {
    right: -35%;
  }
}

@media (max-width: 640px) {
  .show-display {
    display: none;
  }
  .form-display {
    width: 95%;
    padding: 10px;
    margin: auto;
  }
}
</style>
