<template>
  <div class="notify">
    <div class="card info" v-if="notification.type == 'Info'">
      {{ notification.content }}
      <div class="action">
        <a class="btn btn-primary btn-rounded btn-sm" :href="notification.link"
          >Learn more</a
        >
      </div>
    </div>
    <div class="card" v-else>{{ notification.content }}</div>
  </div>
</template>

<script>
export default {
  props: ["notification"],
  name: "Notification",
};
</script>

<style lang="scss" scoped>
.notify {
  .card {
    margin: 20px 0px;
    border-bottom: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
    border-radius: 3px;
    border-left: 4px solid #4caf50;
    padding: 10px;

    &.info {
      .action {
        margin: 5px 0px;
      }
    }
  }
}
</style>
