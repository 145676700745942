import { createStore } from "vuex";
import { auth } from "./auth.module";
import { vendor } from "./vendor.module";
import { product } from "./product.module";
import { storeM } from "./store.module";
import { conversation } from "./conversation.module";

const store = createStore({
  modules: {
    auth,
    vendor,
    product,
    storeM,
    conversation,
  },
});

export default store;
