import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AuthService from "../services/auth.service";
import store from "../store";

// Request interceptor
axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.cellepay.me/api"
    : "http://localhost:3001/api";

axios.interceptors.request.use((request) => {
  let token = localStorage.getItem("token");
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      alert("Network Error");
      return;
    }
    const { status, data } = error.response;

    if (status === 401 && data?.name == "TokenExpiredError") {
      let refresh = localStorage.getItem("refreshToken");
      const res = await AuthService.refresh(refresh);
      let { refreshToken, token, user } = res.data;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);

      store.commit("loginSuccess", user);

      window.location.reload();
    }

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "Server Error",
        text: "We are sorry you experience this, we are working to resolve this issue. If it persist please contact our customer service. Thank you ",
        confirmButtonColor: "#e50913",
      });
    }

    if (axios.isCancel(error)) {
      return console.log(error);
    }

    return Promise.reject(error);
  }
);
export const axiosInstance = axios;
