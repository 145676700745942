import { axiosInstance as axios } from "../plugins/axios";

const ENDPOINT = "/products";

class ProductService {
  get(data) {
    return axios.get(ENDPOINT + "/" + data.id).then((response) => {
      const {
        data: { product },
      } = response;

      return product;
    });
  }

  getProducts(data) {
    return axios
      .get(ENDPOINT + "/vendor", { params: data })
      .then((response) => {
        const {
          data: { results: products },
        } = response;

        return products;
      });
  }

  getCollections(params, id) {
    return axios
      .get(ENDPOINT + "/collections/" + id, { params: params })
      .then((response) => {
        const {
          data: { collections },
        } = response;

        return collections;
      });
  }

  getCollection(id) {
    return axios.get(ENDPOINT + "/collection/" + id).then((response) => {
      const {
        data: { collection },
      } = response;

      return collection;
    });
  }

  submit(data) {
    return axios.patch(ENDPOINT + "/submit/" + data.id);
  }

  create(data) {
    return axios.post(ENDPOINT + "/create", data);
  }

  createCollection(data) {
    return data.mode == "create"
      ? axios.post(ENDPOINT + "/create-collection", data)
      : axios.patch(ENDPOINT + "/update-collection/" + data._id, data);
  }

  update(data, id) {
    return axios.patch(ENDPOINT + "/" + id, data);
  }
  deleteFile(data) {
    return axios.post(ENDPOINT + "/delete/file", data);
  }

  removeCollection(data) {
    return axios.delete(ENDPOINT + "/remove-collection/" + data);
  }

  uploadFile(data) {
    let formData = new FormData();
    formData.append("file", data.file);
    formData.append("product_id", data.product_id);
    return axios.post(ENDPOINT + "/upload", formData);
  }
  async uploadMediaFile(data) {
    let formData = new FormData();
    formData.append("file", data.file);
    const response = await axios.post(ENDPOINT + "/upload-file", formData);
    return response.data;
  }

  getCategories(data) {
    return axios
      .get(`/category`, {
        params: data,
      })
      .then((response) => {
        let {
          data: { categories },
        } = response;

        return categories;
      });
  }
  getFormData() {
    return axios.get(`${ENDPOINT}/form-data`).then((response) => response.data);
  }
  updateStatus(data) {
    return axios.patch(ENDPOINT + "/vs/" + data.id, data);
  }
  downloadMedia(data) {
    return axios.get(ENDPOINT + `/download-media?filePath=${data}`, {
      responseType: "blob",
    });
  }
}

export default new ProductService();
