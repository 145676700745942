<template>
  <div class="">
    <div class="wrapper">
      <div class="sidebar">
        <side-bar />
      </div>
      <div class="body content">
        <transition name="fade" mode="out-in" appear>
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";

export default {
  name: "VendorDashboard",
  components: {
    SideBar,
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  padding-top: 50px;
  gap: 15px;

  .body {
    padding-left: 20px;
    margin-bottom: 100px;
    flex: 1;
    overflow-x: auto;
    border-radius: 20px;
    /* box-shadow: 0px 0px 13px #efefefc9; */
    padding: 20px 10px;
  }
}

@media (max-width: 640px) {
  .wrapper {
    flex-direction: column;
    padding-top: 20px;
    .sidebar {
      display: none;
    }
    .body {
      padding-left: 0px;
    }
  }
}
</style>
