<template>
  <div class="container">
    <div v-if="fetching" class="fetching">
      <span> Loading ... </span>
    </div>
    <div v-else class="col-md-12">
      <div v-if="$route.name == 'board-vendor'">
        <get-started @begin="begin" />
      </div>
      <div v-else>
        <div v-if="message" class="alert alert-danger">{{ message }}</div>
        <!-- <div class="step-con">
          <div class="steps">
            <div class="item" v-for="(s, index) in steps" :key="index">
              <div
                class="inner"
                :class="{ active: index == step, done: step > index }"
              >
                {{ index + 1 }}
              </div>
              <div
                class="text"
                :class="{ active: index == step, done: step > index }"
              >
                <b>{{ s.title }}</b>
              </div>
            </div>
          </div>
        </div> -->

        <div>
          <div class="contain">
            <div
              v-if="$route.name !== 'business-form'"
              @click="back"
              class="back"
            >
              <font-awesome-icon icon="angle-left" />
              <span>Back</span>
            </div>
            <div class="card-container">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GetStarted from "../../components/boarding-steps/get_started.vue";

export default {
  name: "VendorBoard",
  components: {
    GetStarted,
    // BusinessInfo,
    // PersonalInfo,
    // IdentityProof,
  },
  data() {
    return {
      loading: false,
      is_basic: true,
      message: "",
      data: {},
      date: "",
      step: 0,
      fetching: true,
      getStarted: true,
      steps: [
        {
          title: "Business Info",
        },
        // {
        //   title: "Personal Info",
        // },
        // {
        //   title: "Identity Proof",
        // },
      ],
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return true;
    },
    ...mapGetters(["auth/currentUser"]),
  },
  created() {
    this.fetching = true;
    this.$store
      .dispatch("vendor/get")
      .then((vendor) => {
        if (vendor) {
          this.$router.push("/vendor/payment");
        }
      })
      .finally(() => {
        this.fetching = false;
      });
  },
  watch: {
    $route() {
      this.getStep();
    },
  },
  methods: {
    begin() {
      this.getStarted = false;
    },

    handleNext(data) {
      this.data = { ...this.data, ...data };
      if (this.step == 2) {
        this.handleSubmit();
        return;
      }
      this.step = this.step + 1;
    },
    getStep() {
      if (this.$route.name == "business-form") {
        this.step = 0;
      }
      if (this.$route.name == "personal-form") {
        this.step = 1;
      }
      if (this.$route.name == "id-form") {
        this.step = 2;
      }
    },
    back() {
      if (this.$route.name == "id-form") {
        this.$router.push({ name: "personal-form" });
      } else {
        this.$router.push({ name: "business-form" });
      }
    },
    handleSubmit() {
      this.loading = true;
      let formData = new FormData();

      for (var key in this.data) {
        formData.append(key, this.data[key]);
      }
      this.$store.dispatch("vendor/register", formData).then(
        () => {
          this.$router.push("/vendor/payment");
          this.parseSuccess("Vendor Registration Successful");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.parseError(error);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  max-width: 660px !important;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 100px;

  .seller-logo {
    justify-content: center;
  }

  .back {
    margin: 15px 100px;
    color: #665;
    cursor: pointer;
    span {
      margin-left: 10px;
      font-weight: 600;
    }
  }
}

.card-container.card {
  padding: 20px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.step-con {
  width: 640px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .steps {
    display: flex;
    .item {
      margin: 0px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &:after {
        content: "";
        height: 2px;
        width: calc(50% + 50px);
        position: absolute;
        background: #e50913;
        left: 100%;
        top: 25px;
      }
      &:last-child:after {
        display: none;
      }
      .inner {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: rgb(229 9 19 / 30%);
        line-height: 50px;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 10px;

        &.active {
          border: 2px solid #e50913;
        }

        &.done {
          background: #e50913;
        }
      }

      .text {
        &.active {
          color: #e50913;
        }

        &.done {
          color: #e50913;
        }
      }
    }
  }
}

.fetching {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

@media (max-width: 640px) {
  .step-con {
    max-width: 100%;
    .steps {
      .item {
        margin: 0px 20px;
        &:after {
          width: 60%;
          background: #e50913;
          left: 70px;
        }
      }
    }
  }
}
</style>
