export const stringMaxLengthAllowed = {
  url: 120,
  username: 20,
  name: 100,
  email: 254,
  tag: 50,
  title: 80,
  description: 800,
  descriptionMin: 100,
  bio: 200,
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const handleError = (error) => {
  if (error.response.status == 401) {
    localStorage.clear();
    window.location.href = "/";
  }
};
