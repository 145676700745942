<template>
  <div class="get-started">
    <h1>Welcome {{ state.user.username }}, Here is what to expect</h1>
    <br />
    <div class="row">
      <div class="col-md-8">
        <div class="wrapper">
          <ul>
            <li data-count="1">
              <h4>Provide your information and documents</h4>
              <div>
                This section will require you to submit your personal
                information, business information, and documents e. g (your
                valid ID).
              </div>
            </li>

            <li data-count="2">
              <h4>We will verify your submission</h4>
              <div>
                After providing necessary details, then, you sit back while we
                verify your applications. This will probably take maximum of 3
                working days. You will be notified as regards the approval
                status when completed.
              </div>
            </li>

            <li data-count="3">
              <h4>Start selling</h4>
              <div>
                After your your verification has been processed and approved you
                can then proceed to displaying your products in our marketplace
                after the product(s) might have been approved.
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4">
        <div class="need-wrapper">
          <h4>What you will need</h4>
          <ul>
            <li>
              <font-awesome-icon icon="check-circle" />
              <span>Valid Government issued id</span>
            </li>
            <!-- <li>
              <font-awesome-icon icon="check-circle" />
              <span>Bank account</span>
            </li> -->

            <!-- <li>
              <font-awesome-icon icon="check-circle" />
              <span>Chargeable credit card</span>
            </li> -->

            <li>
              <font-awesome-icon icon="check-circle" />
              <span>Mobile phone</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <br />

    <div style="margin: auto; width: 200px">
      <router-link
        class="btn btn-primary btn-block btn-rounded"
        :to="{ name: 'business-form' }"
      >
        Begin now
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "GetStated",
  setup(_, { emit }) {
    const store = useStore();

    const state = reactive({
      user: computed(() => store.state.auth.user),
    });

    const begin = () => {
      emit("begin");
    };

    return {
      state,
      begin,
    };
  },
};
</script>

<style lang="scss" scoped>
.get-started {
  margin: 50px 0px;
  h1 {
    font-weight: 700;
    font-size: 42px;
  }
}

.wrapper {
  ul {
    li {
      position: relative;

      h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;
      }

      &:after {
        content: attr(data-count);
        position: absolute;
        left: -70px;
        top: 20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: #e5091330;
        text-align: center;

        font-size: 24px;
        font-weight: 700;
        color: #e50913;
        line-height: 50px;
      }
    }
  }
}
.need-wrapper {
  h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
  }
  ul {
    li {
      margin: 10px 0px !important;

      span {
        margin-left: 10px;
      }

      svg {
        color: green;
      }
    }
  }
}

@media (max-width: 640px) {
  .get-started {
    margin: 50px 0px;
    h1 {
      font-weight: 700;
      font-size: 24px;
    }
  }
  .wrapper {
    ul {
      margin-left: 60px;

      li {
        h4 {
          font-size: 18px !important;
          line-height: 24px !important;
        }

        &:after {
          left: -70px;
          top: 0px;
        }
      }
    }
  }
}
</style>
