<template>
  <div class="container">
    <vendor-dashboard></vendor-dashboard>
  </div>
</template>

<script>
import VendorDashboard from "../components/VendorDashboard.vue";
// import Payment from "../components/Payment.vue";

export default {
  name: "Dashboard",
  components: {
    //   Payment,
    VendorDashboard,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isVendor() {
      if (this.currentUser.role === "Vendor") {
        return true;
      }
      return false;
    },
    paymentMade() {
      return false;
    },
  },
  methods: {
    checkUser() {
      if (!this.currentUser.confirmed_email && !this.currentUser.confirmed) {
        this.$router.push({ name: "otp-verification" });
      } else if (!this.isVendor) {
        this.$router.push("/vendor/board");
      } else {
        this.$store.dispatch("vendor/get");
      }
    },
  },
  mounted() {
    this.checkUser();
  },
};
</script>
