<template>
  <div class="empty">
    <div>
      <div class="title">
        <h1>{{ title }}</h1>
        <p>{{ subtitle }}</p>
      </div>
      <router-link v-if="route" :to="{ name: route }" class="action">
        <font-awesome-icon icon="plus" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle", "route"],
  name: "Empty",
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  text-align: center;

  .title {
    h1 {
      font-size: 24px;
    }
    p {
      color: rgba($color: #000, $alpha: 0.7);
    }
  }

  .action {
    display: block;
    background: #e50913;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    color: #fff;
    margin: auto;
    line-height: 70px;
    font-size: 24px;
    box-shadow: 0px 0px 0px 10px #e5091317;
  }
}
</style>