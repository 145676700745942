import { axiosInstance as axios } from "../plugins/axios";

const ENDPOINT = "/vendor";
const ORDER_ENDPOINT = "/order";

class VendorService {
  register(data) {
    return axios.post(ENDPOINT + "/register", data);
  }

  get() {
    return axios
      .get(ENDPOINT)
      .then((response) => {
        const {
          data: { vendor, storeCount },
        } = response;
        if (vendor) {
          localStorage.setItem("vendor", JSON.stringify(vendor));
        }
        return { vendor, storeCount };
      })
      .catch(() => {
        console.log("Error");
      });
  }
  setPreview(data) {
    return axios.post(ENDPOINT + "/upload-preview", data);
  }
  verifyPayment(data) {
    return axios.post(ENDPOINT + "/payment/verify", data);
  }
  // No More in use
  updateVendor(data) {
    return axios.patch(ENDPOINT + "/update", data);
  }
  updateProfile(data) {
    return axios.patch(ENDPOINT + "/update-profile", data);
  }
  getOrders(data) {
    return axios.get(ORDER_ENDPOINT + "/vendor", { params: { ...data } });
  }

  getTransactions() {
    return axios.get(ENDPOINT + "/transactions");
  }

  getNotifications(data) {
    return axios.get("/notification/vendor", { params: data });
  }
  markAsRead() {
    return axios.post("/notification/mark-as-read", null, {
      params: { vendor: true },
    });
  }

  getStats() {
    return axios.get(ENDPOINT + "/stats");
  }

  getSettingsFormData() {
    return axios.get(ENDPOINT + "/settings/form-data");
  }

  getPayoutFormData() {
    return axios.get(ENDPOINT + "/payout-form-data");
  }

  createRequest(data) {
    return axios.post(ENDPOINT + "/payout", data);
  }

  getPayouts() {
    return axios.get(ENDPOINT + "/payout");
  }

  cancelRequest(id) {
    return axios.delete(ENDPOINT + "/payout/" + id);
  }

  updateOrderStatus(data) {
    const { id, ...rest } = data;
    return axios.patch(ENDPOINT + "/order/" + id, { ...rest });
  }
}

export default new VendorService();
