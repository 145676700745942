import { axiosInstance as axios } from "../plugins/axios";

const ENDPOINT = "/store";

class ProductService {
  get(data) {
    return axios.get(ENDPOINT + "/" + data.id).then((response) => {
      const {
        data: { store },
      } = response;

      return store;
    });
  }

  getStores(data) {
    return axios.get(ENDPOINT, { params: data }).then((response) => {
      const {
        data: {
          stores: { results: stores },
        },
      } = response;

      return stores;
    });
  }

  async getStore(id) {
    try {
      const response = await axios.get(ENDPOINT + "/show/" + id);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  create(data) {
    return axios.post(ENDPOINT, data);
  }

  getFormData() {
    return axios.get(`${ENDPOINT}/form-data`).then((response) => response.data);
  }

  updateStatus(data) {
    return axios.patch(ENDPOINT + "/vs/" + data.id, data);
  }

  setPreview(id, data) {
    return axios.post(ENDPOINT + "/upload-preview/" + id, data);
  }

  publish(id) {
    return axios.patch(ENDPOINT + "/publish/" + id);
  }
}

export default new ProductService();
