import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  Nav,
  Hero,
  Logo,
  Earning,
  Empty,
  Hint,
  UploadSlot,
  RadarSpinner,
  Notification,
  IconsTime,
  IconsCancel,
} from "./components";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { FontAwesomeIcon } from "./plugins/font-awesome";
import Vuex from "vuex";
import { axiosInstance } from "./plugins/axios";
import { mixin } from "./mixins";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "animate.css";
import Flutterwave from "flutterwave-vue3";
import moment from "moment";
import mitt from "mitt";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Multiselect from "@vueform/multiselect";

const emitter = mitt();
const app = createApp(App);
app.use(router);
app.use(store);
app.use(CKEditor);
app.use(Flutterwave);
app.use(VueReCaptcha, { siteKey: "6Le8lV8iAAAAAB0K95p_naVbrGwYCVSXAooBgw2f" });
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Datepicker", Datepicker);
app.component("multiselect", Multiselect);
app.config.globalProperties.$axios = axiosInstance;
app.config.globalProperties.$emitter = emitter;
Vuex.Store.prototype.$axios = axiosInstance;
app.mixin(mixin);
[
  Nav,
  Hero,
  Logo,
  Earning,
  Empty,
  Hint,
  UploadSlot,
  RadarSpinner,
  Notification,
  IconsTime,
  IconsCancel,
].forEach((Component) => {
  app.component(Component.name, Component);
});

app.config.globalProperties.$filters = {
  formatMoney(value) {
    if (!value == undefined) return "--";
    return value.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",

      //These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
  },
  formatDate(value) {
    return moment(value).format("YYYY-MM-DD");
  },

  formatDateTime(value) {
    return moment(value).format("YYYY-MM-DD hh:mm a");
  },
};

app.mount("#app");
