<template>
  <div>
    <Form
      @submit="handleDone"
      :validation-schema="schema"
      v-slot="{ values }"
      :initial-values="initialVal"
    >
      <div class="contain mt-3">
        <hint
          :title="`Welcome ${currentUser.firstname}!`"
          :body="`To create your vendor account, the next step is to set up your store details.`"
          type=""
        />
        <div class="field">
          <label for="business_name"
            >Brand Name
            <span class="required">*</span>
          </label>
          <p class="hint-text">
            Store brand unique brand name for your business
          </p>
          <Field name="business_name" type="text" class="form-control" />
          <ErrorMessage name="business_name" class="error-feedback" />
        </div>

        <div class="field">
          <label for=""
            >Store Category
            <span class="required">*</span>
          </label>
          <Field
            name="store_category"
            class="form-control"
            id="store_category"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              :options="formData.categories.map(({ category }) => category)"
              class="custom-select"
              placeholder="Select Store Category "
            ></multiselect>
          </Field>
          <ErrorMessage name="store_category" class="error-feedback" />
        </div>

        <div class="field" v-if="values.store_category == 'Others'">
          <label for="other_store_category"
            >Specify Other Category
            <span class="required">*</span>
          </label>
          <Field
            name="other_store_category"
            type="text"
            class="form-control"
            id="other_store_category"
            rules="required"
          />

          <ErrorMessage name="other_store_category" class="error-feedback" />
        </div>

        <div class="field">
          <label for="business_type">Business Type</label>
          <Field name="business_type" as="select" class="form-control">
            <option value="" disabled>Select an entity type</option>
            <option value="Publicly-owned Business">
              Publicly-owned Business
            </option>
            <option value="Privately-owned Business">
              Privately-owned Business
            </option>
            <option value="Charity">Charity</option>
            <option value="Individual">None, I am an Individual</option>
            <ErrorMessage name="business_type" class="error-feedback" />
          </Field>
        </div>

        <div class="field">
          <label for="business_description"
            >Business Description
            <span class="required">*</span>
          </label>
          <small>Give Short description about your business</small>

          <Field
            as="textarea"
            name="business_description"
            type="textarea"
            class="form-control"
          />

          <ErrorMessage name="business_description" class="error-feedback" />
        </div>

        <div class="field">
          <label for="state"
            >State
            <span class="required">*</span>
          </label>
          <Field name="state" type="text" class="form-control" />
          <ErrorMessage name="state" class="error-feedback" />
        </div>

        <div class="field">
          <label for="city"
            >City
            <span class="required">*</span>
          </label>
          <Field name="city" type="text" class="form-control" />
          <ErrorMessage name="city" class="error-feedback" />
        </div>

        <div class="field">
          <label for="address_1"
            >Address
            <span class="required">*</span>
          </label>
          <Field name="address_1" type="text" class="form-control" />
          <ErrorMessage name="address_1" class="error-feedback" />
        </div>

        <div class="feild">
          <label for="coverage_area"
            >Coverage Area
            <span class="required">*</span>
          </label>
          <Field
            name="coverage_area"
            id="coverage_area"
            class="form-control"
            rules="required"
            v-slot="{ field }"
          >
            <multiselect
              v-bind="field"
              :searchable="true"
              :options="cities.map((city) => ({ label: city, value: city }))"
              mode="tags"
              multiple
              class="custom-select"
              placeholder="Select coverage area"
            ></multiselect>
          </Field>
          <ErrorMessage name="coverage_area" class="error-feedback" />
        </div>

        <div class="field my-4">
          <Field
            name="confirm"
            type="checkbox"
            class="form-check-input"
            :value="true"
          />
          <ErrorMessage name="confirm" class="error-feedback" />

          <small>
            i confirm my business name and type is correct. and i understand
            that this information changed later</small
          >
        </div>

        <div class="field">
          <button
            class="btn btn-primary btn-block btn-rounded"
            :disabled="loading"
            style="width: 100%"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Submit</span>
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      business_name: yup.string().required("Business name is required!"),
      business_type: yup.string().required("Business type is required!"),
      address_1: yup.string().required("Address 1 is required!"),
      address_2: yup.string(),
      city: yup.string().required("City is required!"),
      state: yup.string().required("State is required!"),
      confirm: yup.string().required("Business information must be confirmed!"),
      business_description: yup
        .string()
        .test(
          "business_description",
          "Business description must be more than three(3) words",
          (val) => {
            let len = val?.split(" ");
            return len?.length > 3;
          }
        )
        .required("Business description is required"),
    });

    return {
      loading: false,
      message: "",
      schema,
      formData: {
        categories: [],
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    initialVal() {
      return this.$store.getters["vendor/board_form"];
    },
  },
  created() {
    this.getFormData();
    // this.initAutocomplete();
  },
  methods: {
    handleDone(user) {
      this.$store.dispatch("vendor/updateForm", user);
      // this.$router.push({ name: "personal-form" });
      this.loading = true;
      this.$store.dispatch("vendor/register").then(
        () => {
          this.$router.push("/dashboard");
          // this.$router.push("/vendor/payment");
          this.parseSuccess("Vendor Registration Successful");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.parseError(error);
        }
      );
    },
    async initAutocomplete() {
      const config = {
        method: "get",
        url: `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=amoeba&types=establishment&location=37.76999%2C-122.44696&radius=500&key=${process.env.VUE_APP_GOOGLE_API_KEY}`,
        headers: {},
      };
      const response = await this.$axios(config);
      console.log(JSON.stringify(response.data));
    },
    async getFormData() {
      const formData = await this.$store.dispatch("storeM/getFormData");
      this.formData = formData;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-check-input {
  position: relative;
  margin-left: 0px;
  height: 16px;
  width: 24px;
}

.contain {
  max-width: 450px !important;
  margin: auto;

  .seller-logo {
    justify-content: center;
  }
}

.card-container.card {
  padding: 20px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.label-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.or {
  color: #727171;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 30%;
    height: 1px;
    background: #dfdfdf;
  }
  &::before {
    left: -35%;
  }
  &::after {
    right: -35%;
  }
}
</style>
