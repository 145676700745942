<template>
  <div class="">
    <div class="d-flex">
      <div class="show-display">
        <div class="seller-logo">
          <router-link to="/">
            <Logo />
          </router-link>
          <span class="ml-2">Sellers Square</span>
        </div>
        <div class="header-1 mt-4">
          <h4>Welcome Back<br /></h4>
        </div>

        <img src="/images/hero3.png" width="100%" alt="" />
      </div>
      <div class="form-display">
        <div class="seller-logo-m d-sm-none d-block">
          <router-link to="/">
            <Logo />
          </router-link>
          <span class="ml-2 mt-2">Sellers Square</span>
        </div>
        <div class="contain">
          <div class="header">
            <h2>Log in to your Account</h2>
          </div>
          <div class="card-container">
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>

            <Form @submit="handleLogin" :validation-schema="schema">
              <div class="field">
                <label for="email">Email Address</label>
                <Field name="email" type="email" />
                <ErrorMessage name="email" class="error-feedback" />
              </div>
              <div class="field">
                <div class="label-f">
                  <label for="password">Password</label>
                  <router-link to="/">Forgot your password?</router-link>
                </div>
                <Field name="password" type="password" class="form-control" />
                <ErrorMessage name="password" class="error-feedback" />
              </div>

              <div class="form-group mt-4">
                <button
                  class="btn btn-primary btn-block btn-rounded"
                  :disabled="loading"
                >
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Login</span>
                </button>
              </div>

              <div class="mt-4 text-center">
                <span class="or">New to Cellepay?</span>
                <br />
                <router-link
                  to="/register"
                  class="btn btn-primary btn-outlined btn-rounded btn-block mt-4"
                  type="button"
                >
                  <span>Create Your Cellepay account</span>
                </router-link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        (user) => {
          if (user.role === "Vendor") {
            this.$router.push({ name: "dashboard" });
          } else {
            this.$router.push("/vendor/board/business");
          }
        },
        (error) => {
          this.loading = false;
          this.parseError(error);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.show-display {
  width: 40%;
  background: #ffdedf;
  color: #2b0f0f;
  padding: 20px;
  position: sticky;
  top: 0px;
  height: 100vh;

  img {
    width: 100%;
  }

  .header-1 {
    h4 {
      color: rgba(0, 0, 0, 0.7);
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
    }
  }
}

.header {
  margin-bottom: 50px;
  h2 {
    color: #021329;
    font-weight: 700;
    font-size: 24px;
  }
}
.form-display {
  width: 60%;
}

.contain {
  max-width: 450px !important;
  margin: auto;
  margin-top: 100px;

  .seller-logo {
    justify-content: center;
  }
}

.card-container.card {
  padding: 20px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.label-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}
.error-feedback {
  color: red;
}
.or {
  color: #727171;
  position: relative;
  font-size: 12px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 6px;
    width: 30%;
    height: 1px;
    background: #dfdfdf;
  }
  &::before {
    left: -35%;
  }
  &::after {
    right: -35%;
  }
}

@media (max-width: 640px) {
  .show-display {
    display: none;
  }
  .form-display {
    width: 95%;
    padding: 10px;
    margin: auto;
  }

  .header {
    h2 {
      font-size: 32px;
    }
  }
}
</style>
