<template>
  <div>
    <Form
      @submit="handleDone"
      :validation-schema="schema"
      :initial-values="initialVal"
    >
      <div class="contain">
        <div class="row">
          <div class="field col-md-6">
            <label for="first_name">First Name</label>
            <Field name="first_name" type="text" class="form-control" />
            <ErrorMessage name="first_name" class="error-feedback" />
          </div>

          <div class="field col-md-6">
            <label for="last_name">Last Name</label>
            <Field name="last_name" type="text" class="form-control" />
            <ErrorMessage name="last_name" class="error-feedback" />
          </div>
        </div>
        <div class="field">
          <label for="middle_name">Middle Name</label>
          <Field name="middle_name" type="text" class="form-control" />
          <ErrorMessage name="middle_name" class="error-feedback" />
        </div>

        <label>Phone Number</label>
        <div class="row">
          <div class="col-4">
            <div class="field">
              <Field as="select" name="phone_ext" class="form-control">
                <option disabled>Select Extension</option>
                <option value="+234" selected>+234</option>
              </Field>
            </div>
          </div>

          <div class="col-8 field">
            <!-- <label for="phone">phone Number</label> -->
            <Field
              name="phone"
              type="phone"
              placeholder="e.g, 0906383738 "
              class="form-control"
            />
            <ErrorMessage name="phone" class="error-feedback" />
          </div>
        </div>

        <div class="field mt-4">
          <button
            class="btn btn-primary btn-block btn-rounded"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Continue</span>
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "PersonalInformation",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      first_name: yup.string().required("First name is required!"),
      last_name: yup.string().required("Last name is required!"),
      phone_ext: yup.string().required("Phone Ext is required!"),
      phone: yup.string().required("Phone number is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    initialVal() {
      const user = this.$store.getters["auth/currentUser"];
      const init = this.$store.getters["vendor/board_form"];

      let val;
      if (user && !init.first_name && !init.last_name) {
        val = {
          ...init,
          first_name: user.firstname,
          last_name: user.lastname,
        };
      } else {
        val = init;
      }

      return val;
    },
  },
  created() {},
  methods: {
    handleDone(user) {
      this.$store.dispatch("vendor/updateForm", user);
      this.$router.push({ name: "id-form" });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  max-width: 450px !important;
  margin: auto;
  margin-top: 20px;

  .seller-logo {
    justify-content: center;
  }
}

.card-container.card {
  padding: 20px;
}

.card {
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 10px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 4px;
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.label-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}
.error-feedback {
  color: red;
}
.or {
  color: #727171;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 12px;
    width: 30%;
    height: 1px;
    background: #dfdfdf;
  }
  &::before {
    left: -35%;
  }
  &::after {
    right: -35%;
  }
}
</style>
