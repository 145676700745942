import { axiosInstance as axios } from "../plugins/axios";

const ENDPOINT = "/auth";

class AuthService {
  login(user) {
    return axios
      .post(ENDPOINT + "/login", {
        username: user.email,
        password: user.password,
      })
      .then((response) => {
        const {
          data: { user, vendor, token, refreshToken },
        } = response;
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
        }

        return { user, vendor };
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("vendor");
    localStorage.removeItem("refreshToken");
  }

  register(user) {
    return axios.post(ENDPOINT + "/create", user).then((response) => {
      const {
        data: { user, token, refreshToken },
      } = response;
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
      }

      return user;
    });
  }
  verifyOtp(otp) {
    return axios.post(ENDPOINT + "/otp-verify", otp);
  }

  resendOtp() {
    return axios.post(ENDPOINT + "/resend-otp");
  }

  refresh(refreshToken) {
    return axios.post("auth/refresh", { refreshToken });
  }

  changePassword(data) {
    return axios.patch(ENDPOINT + "/change-password", data);
  }

  submitId(data) {
    return axios.post("/id", data);
  }

  getId() {
    return axios.get("/id").then((res) => res.data.id);
  }
}

export default new AuthService();
