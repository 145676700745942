<template>
  <div class="hero">
    <div class="container">
      <div class="wrapper row">
        <div class="details col-md-6 col-lg-6 col-12">
          <!-- <div class="gradient"></div> -->
          <h1>Empower your business with a digital storefront on CellePay.</h1>
          <p>
            Step into the future of commerce and elevate your brand presence
            with CellePay's cutting-edge online store platform.
          </p>

          <div class="actions">
            <div class="flex-col">
              <div class="mb-2">
                <router-link v-slot="{ href, navigate }" to="/register">
                  <button
                    :href="href"
                    @click="navigate"
                    class="btn btn-primary md btn-rounded"
                  >
                    Get Started for Free
                  </button>
                </router-link>
              </div>
              <!-- <small class="text-primary">N500 signup fee</small> -->
            </div>
            <!-- <button>Learn</button> -->
          </div>
        </div>
        <div class="preview col-md-6 col-lg-6 col-12">
          <img src="/images/hero2.png" width="100%" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style lang="scss" scoped>
.hero {
  /* background: linear-gradient(45deg, black, transparent); */
  height: 460px;
  color: #444;
  /* box-shadow: -2px -3px 5px #180909; */

  .wrapper {
    .details {
      margin-top: 100px;
      position: relative;
      .gradient {
        position: absolute;
        height: 80px;
        width: 230px;
        background-image: linear-gradient(rgb(229, 9, 19), rgb(249, 219, 211));
        top: 62px;
        z-index: 0;
        border-radius: 50px;
        left: 32%;
        filter: blur(100px);
      }
      h1 {
        font-size: 3rem;
        font-weight: 700;
        position: relative;
        z-index: 1;
        letter-spacing: -1.4px;
      }
      p {
        font-size: 16px;
      }
    }

    .preview {
      border-radius: 10px;
      z-index: 2;
      position: relative;
      img {
        border-radius: 10px;
        height: 100%;
        width: 100%;
        margin-top: 20px;
      }
      /* 
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: -23px;
        left: -23px;
        background: url(/images/hero.jpg);
        background-size: cover;
        z-index: 1;
        border-radius: 10px;
      } */
    }
  }
}

@media (max-width: 640px) {
  .hero {
    height: 100vh;
    padding: 50px 0px;
    .wrapper {
      .details {
        h1 {
          font-size: 2.8rem;
          line-height: 3.2rem;
        }
        p {
          font-size: 16px;
        }
      }

      .preview {
        /* display: none; */
      }
    }
  }
}
</style>
