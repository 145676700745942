<template>
  <div id="app">
    <Nav v-if="!['login', 'register'].includes($route.name)" />
    <div>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // showDasboard() {
    //   return false
    // },
    // showVendorRegistration() {
    //   if (false) {
    //     return false;
    //   }

    //   return false;
    // },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
@import "./assets/custom.scss";
@import "~sweetalert2/dist/sweetalert2";
@import "~@vueform/multiselect/themes/default.css";
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Poppins", sans-serif;
} */
</style>
