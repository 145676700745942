import VendorService from "../services/vendor.service";

const ven = JSON.parse(localStorage.getItem("vendor"));

const initialState = {
  vendor: ven ? ven : null,
  orders: [],
  board_form: {
    coverage_area: [],
  },
};

export const vendor = {
  namespaced: true,
  state: initialState,
  actions: {
    register({ state }) {
      let formData = new FormData();

      for (var key in state.board_form) {
        formData.append(key, state.board_form[key]);
      }
      return VendorService.register(formData).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateForm({ commit }, data) {
      commit("updateForm", data);
    },
    get({ commit }) {
      return VendorService.get()
        .then((data) => {
          const { vendor, storeCount } = data || {};

          commit("vendorFetched", { ...vendor, storeCount });
          return Promise.resolve(vendor);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    getOrders({ commit }, data) {
      return VendorService.getOrders(data)
        .then((response) => {
          const {
            data: { orders },
          } = response;

          commit("ordersFetched", orders);
          return Promise.resolve(orders);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTransactions() {
      return VendorService.getTransactions()
        .then((response) => {
          const {
            data: { transactions },
          } = response;

          return Promise.resolve(transactions);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getNotifications(_, data) {
      return VendorService.getNotifications(data)
        .then((response) => {
          const {
            data: { notifications },
          } = response;

          return Promise.resolve(notifications);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getStats() {
      return VendorService.getStats()
        .then((response) => {
          const {
            data: { monthEarnings },
          } = response;

          return Promise.resolve(monthEarnings);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    markAsRead() {
      return VendorService.markAsRead()
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    setPreview(_, data) {
      return VendorService.setPreview(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    verifyPayment(_, data) {
      return VendorService.verifyPayment(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    // No more in use
    updateVendor(_, data) {
      return VendorService.updateVendor(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateProfile(_, data) {
      return VendorService.updateProfile(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getSettingsFormData() {
      return VendorService.getSettingsFormData().then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getPayoutFormData() {
      return VendorService.getPayoutFormData()
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    createRequest(_, data) {
      return VendorService.createRequest(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getPayouts() {
      return VendorService.getPayouts()
        .then((response) => {
          return Promise.resolve(response?.data?.payouts);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    cancelRequest(_, id) {
      return VendorService.cancelRequest(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateOrderStatus(_, data) {
      return VendorService.updateOrderStatus(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    vendorFetched(state, vendor) {
      state.vendor = vendor;
    },
    ordersFetched(state, orders) {
      state.orders = orders;
    },
    updateForm(state, data) {
      state.board_form = {
        ...state.board_form,
        ...data,
      };
    },
  },
  getters: {
    vendor: (state) => state.vendor,
    orders: (state) => state.orders,
    board_form: (state) => state.board_form,
  },
};
