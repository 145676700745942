import { createWebHashHistory, createRouter } from "vue-router";
import { Register, Home, Login } from "../pages";

// lazy-loaded

const Dashboard = () => import("../pages/dashboard.vue");
const Dash = () => import("../pages/dash.vue");

const BoardVendor = () => import("../pages/vendor/board");
const VendorPayment = () => import("../pages/vendor/payment");
const VendorCollections = () => import("../pages/vendor/collections");
const CreateCollections = () => import("../pages/vendor/collections/create");
const Profile = () => import("../pages/store/profile");
const Orders = () => import("../pages/vendor/orders");
const Products = () => import("../pages/vendor/products");
const ProductForm = () => import("../pages/product/form");
const Transactions = () => import("../pages/vendor/transactions");
const Notification = () => import("../pages/vendor/notifications");
const OtpVerification = () => import("../pages/auth/otp-verification");
const Settings = () => import("../pages/vendor/settings");
import BusinessInfo from "../components/boarding-steps/business_info.vue";
import PersonalInfo from "../components/boarding-steps/personal_info.vue";
import IdentityProof from "../components/boarding-steps/identity_proof.vue";

const Store = () => import("../pages/store/index");
const StoreView = () => import("../pages/store/show.vue");
const CreateStore = () => import("../pages/store/create");

const PayoutRequests = () => import("../pages/payout/index.vue");
const PayoutRequestsForm = () => import("../pages/payout/form.vue");
const Conversations = () => import("../pages/conversations.vue");
const Verification = () => import("../pages/verification.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  {
    path: "/register",
    component: Register,
    name: "register",
  },
  {
    path: "/otp-verification",
    component: OtpVerification,
    name: "otp-verification",
  },
  {
    path: "/vendor/board",
    name: "board-vendor",
    // lazy-loaded
    component: BoardVendor,
    children: [
      {
        path: "business",
        name: "business-form",
        component: BusinessInfo,
      },
      {
        path: "personal",
        name: "personal-form",
        component: PersonalInfo,
      },
      {
        path: "identification",
        name: "id-form",
        component: IdentityProof,
      },
    ],
  },
  {
    path: "/vendor/payment",
    name: "vendor-payment",
    // lazy-loaded
    component: VendorPayment,
  },

  {
    path: "/dashboard",
    name: "vendor",
    component: Dashboard,
    redirect: { name: "dashboard" },
    // lazy-loaded
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dash,
      },
      {
        path: "store",
        name: "store",
        component: Store,
      },
      {
        path: "store/create",
        name: "create-store",
        component: CreateStore,
      },
      {
        path: "store/profile",
        name: "store-profile",
        component: Profile,
      },
      {
        path: "store/:id",
        name: "store-view",
        component: StoreView,
      },
      {
        path: "products",
        name: "products",
        component: Products,
      },
      {
        path: "orders",
        name: "orders",
        component: Orders,
      },
      {
        path: "transactions",
        name: "transactions",
        component: Transactions,
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notification,
      },
      {
        path: "payout-requests",
        name: "payout-requests",
        component: PayoutRequests,
      },
      {
        path: "create-payout-requests",
        name: "create-payout-requests",
        component: PayoutRequestsForm,
      },
      {
        path: "edit-payout-requests/:id",
        name: "edit-payout-requests",
        component: PayoutRequestsForm,
      },
      {
        path: "product/image-edit/:id/:edit",
        name: "edit-product-image",
        component: () =>
          import(
            /* webpackChunkName: "Details" */ "@/components/product/product-images.vue"
          ),
      },
      {
        path: "/product/create",
        name: "create-product",
        component: ProductForm,
      },

      {
        path: "/product/edit/:id",
        name: "edit-product",
        component: ProductForm,
      },
      {
        path: "/vendor/collections",
        name: "vendor-collections",
        // lazy-loaded
        component: VendorCollections,
      },
      {
        path: "/vendor/collection/create",
        name: "create-collection",
        // lazy-loaded
        component: CreateCollections,
      },
      {
        path: "/vendor/collection/edit/:id",
        name: "edit-collection",
        // lazy-loaded
        component: CreateCollections,
      },
      {
        path: "/conversations",
        name: "conversations",
        // lazy-loaded
        component: Conversations,
      },
      {
        path: "/dashboard/id-verification",
        name: "id-verification",
        component: Verification,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, _, next) => {
//   if (to.name !== "login" && to.meta.auth) {
//     console.log(store.state);
//     next({ name: "Login" });
//   } else next();
// });

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register", "/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (loggedIn && publicPages.includes(to.path)) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
