import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faCheckCircle,
  faClock,
  faCog,
  faBell,
  faAngleRight,
  faEllipsisH,
  faEllipsisV,
  faPlus,
  faLightbulb,
  faFilter,
  faPencilAlt,
  faTimes,
  faArrowLeft,
  faAngleLeft,
  faBars,
  faAngleDown,
  faTrash,
  faEdit,
  faInbox,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faCheckCircle,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faClock,
  faCog,
  faBell,
  faAngleRight,
  faEllipsisH,
  faEllipsisV,
  faPlus,
  faLightbulb,
  faFilter,
  faPencilAlt,
  faTimes,
  faArrowLeft,
  faAngleLeft,
  faAngleDown,
  faBars,
  faTrash,
  faEdit,
  faInbox
);

export { FontAwesomeIcon };
