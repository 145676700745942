import StoreService from "../services/store.service";

const initialState = {
  stores: [],
  form: {},
};

export const storeM = {
  namespaced: true,
  state: initialState,
  actions: {
    getStores({ commit }, data) {
      return StoreService.getStores(data).then(
        (stores) => {
          console.log(stores);
          commit("setStores", stores);
          return Promise.resolve(stores);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getStore(_, data) {
      return StoreService.getStore(data).then(
        (store) => {
          console.log(store);
          return Promise.resolve(store);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFormData() {
      return StoreService.getFormData().then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    create(_, data) {
      // let formData = new FormData();

      // for (var key in data) {
      //   if (key == "images") {
      //     for (let i = 0; i < data[key].length; i++) {
      //       formData.append("images", data[key][i]);
      //     }
      //   } else {
      //     formData.append(key, data[key]);
      //   }
      // }

      return StoreService.create(data).then(
        (response) => {
          return Promise.resolve(response.data.store);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    update(_, data) {
      return StoreService.update(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateStatus(_, data) {
      return StoreService.updateStatus(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteFile(_, data) {
      return StoreService.deleteFile(data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    publish(_, id) {
      return StoreService.publish(id).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    setPreview(_, { id, data }) {
      return StoreService.setPreview(id, data).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },

  mutations: {
    setStores(state, stores) {
      state.stores = stores;
    },
  },
  getters: {
    stores: (state) => state.stores,
  },
};
