import { axiosInstance as axios } from "../plugins/axios";

const ENDPOINT = "/conversation";

class ConversationService {
  getConversations() {
    return axios.get(ENDPOINT).then((response) => {
      const {
        data: { conversations },
      } = response;

      return conversations;
    });
  }

  createConversation(data) {
    return axios.post(ENDPOINT, data);
  }

  getConversation(conversationId) {
    return axios.get(ENDPOINT + "/" + conversationId).then((response) => {
      const {
        data: { conversation },
      } = response;

      return conversation;
    });
  }

  createMessage(data) {
    return axios.post(ENDPOINT + "/message", data);
  }
}

export default new ConversationService();
